
export interface IContact {
  email: string;
  name: string;
  phone: string;
  postcode: string;
  desc: string;
  template: string;
}

export class Contact implements IContact {
  email: string;
  name: string;
  phone: string;
  postcode: string;
  desc: string;
  template: string;
}
